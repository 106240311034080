.about-container {
  text-align: center;
  margin-top: 50px;
  padding: 0 10px; /* Add horizontal padding for mobile */
}

/* Flickering Heading */
.about-flicker {
  font-size: 3em;
  margin-bottom: 40px;
  color: #ff9900;
  text-shadow: 0 0 15px #ff9900, 0 0 25px #ff6600;
  animation: flicker 1.5s infinite alternate;
}

@keyframes flicker {
  0% { opacity: 1; }
  50% { opacity: 0.7; }
  100% { opacity: 1; }
}

.sections-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  flex-wrap: wrap;
  height: auto; /* Ensure height adjusts dynamically */
}

/* Left and right sections remain side-by-side on larger screens */
.left-section, .right-section {
  width: 48%; /* Set width for larger screens */
  padding: 20px;
  box-sizing: border-box;
  color: white;
  min-height: 600px; /* Set minimum height */
}

.left-section {
  background-image: url('../assets/aboutScript.png'); /* Add your background image */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 60%;
}

.right-section {
  background-color: rgba(0, 0, 0, 0);
  padding: 20px;
  width: 40%;
}

.bottom-section {
  margin-top: 20px;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0);
  color: white;
}

/* Responsive styles for smaller screens */
@media (max-width: 1024px) {
  .left-section, .right-section {
    width: 100%; /* Full width on medium screens */
    min-height: 650px; /* Adjust minimum height */
  }
}

@media (max-width: 768px) {
  .sections-container {
    flex-direction: column; /* Stack sections vertically */
  }

  .left-section, .right-section {
    width: 100%; /* Full width for small screens */
    min-height: 510px; /* Reduce minimum height */
  }

  .flicker {
    font-size: 36px; /* Adjust heading font size */
  }
}

@media (max-width: 480px) {
  .flicker {
    font-size: 24px; /* Further reduce heading font size for mobile */
  }

  .left-section, .right-section {
    min-height: 300px; /* Further reduce minimum height for mobile */
  }
}

.bottom-section {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px;
  color: #fff;
  position: relative; /* Changed from absolute to relative */
  bottom: 0; 
  width: 100%; 
  height: 100%; /* Adjusted height to match box height */
  box-sizing: border-box;
  margin-top: 40px; /* Added margin to ensure spacing from other content */
}

.box {
  text-align: center;
  padding: 15px;
  border-radius: 5%; /* Make the box round */
  background-color: rgba(30, 30, 30, 0.9); /* Darker background for spooky effect */
  width: 200px; /* Adjusted width for round shape */
  height: 200px; /* Adjusted height for round shape */
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: background-color 0.3s ease;
  margin-bottom: 50px;
  box-shadow: 0 0 20px rgba(244, 244, 244, 0.5); /* Glowing red shadow */
}

/* Box Image */
.box img {
  width: 100px; /* Adjusted size for round box */
  height: 100px; /* Adjusted size for round box */
  display: block;
  margin-bottom: 10px;
}

/* Box Paragraph */
.box p {
  margin: 5px 0; 
  font-size: 16px; 
  color: #fff;
  text-align: center;
  font-weight: bold;
  background: linear-gradient(90deg, #ffffff, #ffffff); /* Gradient for spooky text */
  background-size: 200%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 2s linear infinite;
}

/* Box Info */
.box .info {
  font-size: 24px; /* Slightly smaller for a round box */
  color: #ccc;
  margin-top: 5px;
  font-weight: bold;
}

/* Box Main Info */
.box .main-info {
  font-size: 24px; /* Slightly smaller for a round box */
  color: #06c0f4;
  margin-top: 5px;
  font-weight: bold;
}

/* Hover Effects */
.box:hover p {
  color: #ffffff;
}

.box:hover .main-info {
  color: #ffffff; /* Change main info color on hover */
}

/* Shine Animation */
@keyframes shine {
  0% {
      background-position: 200%;
  }
  100% {
      background-position: -200%;
  }
}

.gallery-container {
  overflow: hidden; /* Hide overflow for the sliding effect */
  position: relative;
  width: 100%; /* Full width */
  margin-top: 40px; /* Add margin to separate from previous content */
}

.gallery {
  display: flex; /* Flexbox for horizontal layout */
  animation: scroll 20s linear infinite; /* Animation to move the gallery */
}




@keyframes scroll {
  0% {
    transform: translateX(0); /* Start at the original position */
  }
  100% {
    transform: translateX(-50%); /* Move to the left by 50% of the total width */
  }
}

.gallery-heading {
  font-size: 36px; /* Size of the gallery heading */
  color: #ffcc00; /* Spooky yellow color */
  animation: flicker 1s infinite alternate; /* Flicker animation */
  margin-bottom: 20px; /* Space below the heading */
}

.gallery img {
  width: 400px; /* Increased width of each image */
  height: 300px; /* Maintain aspect ratio */
  margin-right: 20px; /* Space between images */
  border: 2px solid rgba(255, 204, 0, 0.5); /* Spooky border around images */
  border-radius: 10px; /* Rounded corners for images */
}
