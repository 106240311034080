.contact-section {
  /* background-color: #0d0026; */
  
  color: #ffea00; /* Yellow text */
  padding: 100px 20px; /* Reduced padding for smaller screens */
  text-align: center;
}

.contacts-background {
  display: flex;
  background-color: black;
  width: 100%;
  align-items: flex-start; /* Align items to the top of the flex container */
  justify-content: space-around;
  height: auto; /* Adjusts height based on content */
  padding: 20px; /* Adds padding around the content */
  box-sizing: border-box; /* Includes padding and border in element's total width and height */
  flex-wrap: wrap; /* Allows items to wrap to the next line if needed */
}

.contacts-section {
  display: flex;
  align-items: flex-start; /* Aligns items to the top of the flex container */
  font-family: monospace;
  justify-content: space-between; /* Ensures space between items */
  width: 100%;
  flex-wrap: wrap; /* Enables wrapping for small screens */
}

.contacts-logo img {
  margin-top: 1em;
  height: 7em;
}

.contacts-logo {
  color: white;
  width: 100%;
  max-width: 30em;
  margin-left: 0;
  margin-bottom: 20px;
  text-align: center; /* Centers content for smaller screens */
}

.contacts-info {
  font-size: 1rem;
  color: white;
  margin-top: 20px;
  text-align: left;
}

.contacts-writing {
  margin-right: 0;
  margin-top: 1em;
  display: flex;
  gap: 25px;
  flex-wrap: wrap; /* Allows the items to wrap on smaller screens */
  justify-content: center; /* Centers the items on smaller screens */
}

.reach-out, .quick-links, .contact {
  margin-top: 30px;
  color: white;
  font-family: monospace;
  width: 160px;
  text-align: center; /* Centers the text content */
  position: relative;
}

.follow-us{
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: monospace;
  text-align: center;
  position: relative;
}

.reach-out, .quick-links {
  border-right: 2px solid gray; /* Adds vertical line between sections */
  padding-right: 10px;
}

.reach-out-heading, .quick-links-heading, .follow-us-heading, .contact-heading {
  font-weight: 900;
  font-size: 1.3em;
  color: gray;
}

.quick-links-content {
  list-style: none;
  display: block;
  margin-top: 10px;
  font-size: 1rem;
}

.quick-links-content a {
  text-decoration: none;
  color: white;
}

.quick-links-content div {
  margin-bottom: 10px;
}

.reach-out-address,
.contact-numbers {
  font-size: 1rem;
  margin-top: 10px;
}

.social-icons {
  display: flex;
  justify-content: center; /* Centers icons in the container */
  gap: 10px;
}

.social-icons img {
  height: 50px;
  margin-top: 0px;
  margin-right: 0px;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .contacts-background {
      flex-direction: column; /* Stacks elements vertically */
      align-items: center; /* Centers items */
      text-align: center; /* Centers text */
      height: auto; /* Auto height for stacking */
      padding: 20px 10px; /* Adjust padding */
  }

  .contacts-logo {
      margin: 0 auto 20px auto;
      width: auto;
  }

  .contacts-writing {
      flex-direction: column; /* Stacks items vertically */
      align-items: center;
      gap: 15px;
  }

  .reach-out,
  .quick-links,
  .follow-us,
  .contact {
      border-right: none; /* Remove border on small screens */
      border-bottom: 2px solid gray; /* Add a horizontal line instead */
      width: 100%;
      max-width: 250px; /* Sets a max width for readability */
      margin: 10px 0; /* Adds space between elements */
  }
}

@media (max-width: 480px) {
  .contact-section {
      padding: 50px 10px; /* Further reduce padding for small screens */
  }

  .contacts-logo img {
      height: 5em; /* Adjust logo size for very small screens */
  }

  .quick-links-content {
      font-size: 0.9rem; /* Smaller font size for mobile */
  }

  .reach-out-address,
  .contact-numbers {
      font-size: 0.9rem; /* Adjust font size for readability */
  }
}

/* Spooky border style */
.spooky-border {
  margin-top: 3%;
  border: 4px solid #d4a517; /* Yellowish color */
  border-radius: 10px; /* Optional: adds slight curve for a rough look */
  box-shadow: 
      0 0 15px rgba(212, 165, 23, 0.8), /* Outer yellowish glow */
      0 0 30px rgba(0, 0, 0, 0.6), /* Outer dark shadow for depth */
      0 0 5px rgba(0, 0, 0, 0.5) inset; /* Inner shadow to give depth */
  background-color: #333; /* Dark background for contrast */
  padding: 2px; /* Space around content */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8); /* Optional: spooky text shadow */
  font-family: 'Creepster', cursive; /* Optional: spooky font */
  transition: 0.3s ease-in-out;
}

.spooky-border:hover {
  box-shadow: 
      0 0 30px rgba(212, 165, 23, 1), /* Increased glow on hover */
      0 0 50px rgba(0, 0, 0, 0.8), /* Deeper shadow on hover */
      0 0 10px rgba(0, 0, 0, 0.7) inset; /* Darker inner shadow on hover */
}
