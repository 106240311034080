.events-section {
  /* background-color: #1a0033; */

 
  color: #ffea00; /* Yellow text */
  padding: 150px 20px; /* Adjusted padding for responsiveness */
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* min-height: 100vh; Ensures the section covers the full viewport height */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.events-section h2 {
  font-size: 2.5rem; /* Scalable font size */
  margin-bottom: 20px;
  line-height: 1.2; /* Improved line spacing */
}

.events-section ul {
  list-style: none;
  padding: 0;
  margin: 0;
  max-width: 600px; /* Limit the width for better readability */
  width: 100%;
}

.events-section li {
  font-size: 1.25rem; /* Scalable font size */
  margin: 10px 0;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .events-section {
      padding: 100px 20px; /* Reduced padding for smaller screens */
  }

  .events-section h2 {
      font-size: 2rem; /* Smaller font size for smaller screens */
  }

  .events-section li {
      font-size: 1rem; /* Smaller font size for smaller screens */
  }
}

@media (max-width: 480px) {
  .events-section {
      padding: 80px 10px; /* Further reduced padding for very small screens */
  }

  .events-section h2 {
      font-size: 1.5rem; /* Smaller font size for very small screens */
  }

  .events-section li {
      font-size: 0.9rem; /* Smaller font size for very small screens */
  }
}
.event-cards{
  margin-top: 4%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  justify-content: center;
  align-items: center;
  gap: 5%;
  margin-bottom: 5%;

}
a{
  text-decoration: none;
}
.buttons {
  text-decoration: none;
  width: 100%;
  background-color: #3a0e64; /* Dark purple for a spooky vibe */
  border-radius: 10px;
  color: #ecec25; /* Ghostly yellow */
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10%;
  margin-bottom: 17%;
  font-family: 'Creepster', cursive; /* Use a spooky font */
  font-size: 1.2rem;
  letter-spacing: 2px;
  /* box-shadow: 0 0 15px rgba(255, 0, 0, 0.8), 0 0 30px rgba(255, 0, 0, 0.6);  */
  border: 2px solid #ecec25;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.buttons:hover {
  
  transform: scale(1.1); /* Slight grow on hover */
  box-shadow: 0 0 25px rgba(255, 187, 0, 0.9), 0 0 50px rgba(255, 204, 0, 0.7); /* Intense glow on hover */
}

.buttons:active {
  background-color: #b22222; /* Darker red when clicked */
  transform: scale(0.95); /* Slight shrink when clicked */
}

/* Spooky text flicker animation */
.buttons h3 {
  animation: flicker 1.5s infinite alternate;
}

@keyframes flicker {
  0% {
    opacity: 1;
    text-shadow: 0 0 5px rgba(236, 236, 25, 0.9), 0 0 10px rgba(236, 236, 25, 0.8);
  }
  50% {
    opacity: 0.8;
    text-shadow: 0 0 10px rgba(255, 69, 0, 0.7), 0 0 20px rgba(255, 69, 0, 0.6);
  }
  100% {
    opacity: 1;
    text-shadow: 0 0 15px rgba(236, 236, 25, 0.9), 0 0 30px rgba(236, 236, 25, 0.8);
  }
}

/* Flickering Heading */
.events-flicker {
  font-size: 3em;
  margin-bottom: 40px;
  color: #ff9900;
  text-shadow: 0 0 15px #ff9900, 0 0 25px #ff6600;
  animation: flicker 1.5s infinite alternate;
}

@keyframes flicker {
  0% { opacity: 1; }
  50% { opacity: 0.7; }
  100% { opacity: 1; }
}
