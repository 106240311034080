.home-section {
  background-size: cover;
  background-position: center center;
  height: 100vh;
  padding: 0;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  overflow: hidden;
  position: relative; /* Positioning context */
}

/* Cloud Styles */
.cloud, .cloud2, .cloud3 {
  position: absolute;
  top: 13%;
  width: 120px; /* Default size */
  height: auto;
  z-index: 2;
}

.cloud {
  left: 50%;
  transform: translate(-50%, 0); /* Center horizontally */
}

.cloud2 {
  left: 0%;
  transform: translate(-50%, 0); /* Offset horizontally */
}

.cloud3 {
  left: 100%;
  transform: translate(-50%, 0); /* Offset horizontally */
}

/* Moon Styles */
.moonhome {
  position: absolute;
  display: flex;
  justify-content: center;
  bottom: 38%;
  transform: translate(-50%, -50%);
  width: 100%; 
  height: auto;
}

/* Hand Styles */
.hand {
  display: flex;
  justify-content: center;
  
  position: absolute;
  bottom: 2%;
  transform: translate(-50%, 0);
  width: 100%; 
  height: 600px;
  z-index: 2;
}

/* Grass Styles */
.grass-1, .grass-2 {
  position: absolute;
  left: 0%;
  bottom: 0%;
  width: 100%;
  height: 290px; /* Default size */
  background-size: cover;
  background-repeat: repeat-x;
}



.grass-2 {
  z-index: 4;
  background-image: url(../assets/grass.png);
}

/* Handhole Styles */
.handhole {
  position: absolute;
  bottom: 2%;
  transform: translate(-50%, 0);
  width: 100%; /* Default size */
  height: auto;
  z-index: 3;
  display: flex;
  justify-content: center;
}

/* MEDIA QUERIES */

/* Tablet devices */
@media (max-width: 768px) {
  .cloud, .cloud2, .cloud3 {
    width: 90px; /* Smaller cloud size */
  }

  .moonhome img{
    width: 80px; /* Smaller moon size */
  }

  /* .hand {
    width: 80px;
  } */

  .grass-1, .grass-2 {
    height: 220px; 
  }

  /* .handhole {
    width: 80%;
  } */
}

/* Mobile devices */
@media (max-width: 480px) {
  .cloud, .cloud2, .cloud3 {
    width: 60px; /* Smaller cloud size */
  }
  .grass-1, .grass-2 {
    height: 180px; 
  }
}

/* Small mobile devices (iPhone SE) */
@media (max-width: 320px) {
  .cloud, .cloud2, .cloud3 {
    width: 50px; /* Even smaller cloud size */
  }


  .grass-1, .grass-2 {
    height: 150px; 
  }

  
}
