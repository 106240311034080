@import url('https://fonts.googleapis.com/css2?family=Creepster&display=swap');

body {
  margin: 0;
  font-family: 'Arial', sans-serif;
  background: linear-gradient(
    to bottom, 
    #8b00ff 0%,  /* Lighter purple continuing from About page */
    #6a0dad 100% /* Slightly darker purple */
  );
  background-image: url(../src/assets/zombie.png);
  background-attachment: fixed;
  background-color: #2b004d; /* Deep purple background */
  color: #ffea00; /* Yellow text */
  font-family: 'Emilys Candy', cursive;
  /* cursor: none; */
  overflow-x: hidden;
}

section {
  border-bottom: 1px solid #ffea00;
}

section:last-of-type {
  border-bottom: none;
}



