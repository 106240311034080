.faq-section {
    width: 90%;
    max-width: 90%;
    margin: 0 auto;
    padding: 2rem 1rem;
  }
  
  .faq-heading {
    font-size: 3rem;
    text-align: center;
    color: #ffae42;
    text-shadow: 0 0 10px #ffae42, 0 0 20px #ff4500;
    animation: flicker 2s infinite ease-in-out;
    margin-bottom: 2rem;
  }
  
  .faq-list {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
  }
  
  .faq-item {
    position: relative;
    padding: 1rem 0;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .faq-item.active .faq-question {
    color: #ffae42;
  }
  
  .faq-item:hover {
    transform: scale(1.02);
  }
  
  .faq-question {
    font-size: 1rem;
    font-weight: bold;
    color: #ffae42;
    text-shadow: 0 0 4px #ffae42, 0 0 8px #ff4500;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 1rem; /* Adjust to move + symbol closer */
  }
  
  .faq-icon {
    font-size: 1.5rem;
    color: #ff4500;
    transition: color 0.3s ease;
    margin-left: -10px; /* Adjust to bring + symbol closer to question */
  }
  
  .faq-answer {
    max-height: 0;
    overflow: hidden;
    color: #ffffff;
    font-size: 1rem;
    opacity: 0;
    transition: max-height 0.4s ease, opacity 0.4s ease;
  }
  
  .faq-answer.show {
    max-height: 150px;
    opacity: 1;
  }
  
  .faq-answer p {
    margin: 0;
  }
  
  /* Static Lines and Color Animation */
  .faq-item::before,
  .faq-item::after {
    content: '';
    position: absolute;
    left: -20px;  /* Extend line to the left */
    right: -20px; /* Extend line to the right */
    height: 2px;
    background: #ff4500;
    transform: scaleX(1); /* Always visible */
    transition: background-color 0.4s ease;
  }
  
  .faq-item.active::before,
  .faq-item.active::after {
    background-color: #ffae42;
  }
  
  .faq-item::before {
    top: 0;
  }
  
  .faq-item::after {
    bottom: 0;
  }
  
  /* Flickering Animation for the Heading */
  @keyframes flicker {
    0%, 100% { opacity: 1; }
    50% { opacity: 0.85; }
    75% { opacity: 0.65; }
  }
  
  /* Responsive styling */
  @media (max-width: 600px) {
    .faq-heading {
      font-size: 2.5rem;
    }
  
    .faq-question {
      font-size: 1.2rem;
    }
  
    .faq-item {
      padding: 0.8rem 0;
    }
  
    .faq-answer {
      font-size: 0.9rem;
    }
  }