body {
    margin: 0;
    /* overflow: hidden; */
  }
  
  .center {
    position:relative;
    margin: auto;
    top: 0; bottom: 0;
    left: 0; right: 0;
  }
  
  #canvas {
    position: relative;
    top:220px;
    
  }
  @media (min-width: 1024px) {
    #canvas{
      left:-90px;
    }
  }
  .gooeys {
      width: 100%;
      height: 100%;
      filter: url('#filter');
  }
  
  .bg {
      width: 390px;
      height: 390px;
      background-color: white; 
      border-radius: 50%; 
      transition: all 0.5s;  
  }
  
  .pot {
    box-sizing: border-box;
    width: 250px;
    height: 120px;
    /* top: 100px; */
    background: radial-gradient(rgb(59, 36, 0), #111 45%);
    border-radius: 25px 25px 80px 80px;
  }
  
  .pot-top {
    box-sizing: border-box;
    width: 250px;
    height: 40px;
    background-color: #111;
    /* top: -50px; */
    border-radius: 20px 20px 80px 80px;
    box-shadow: rgb(255, 213, 28) 0 10px 45px -17px inset; 
  }
  
  .highlight{
    content: "";
    /* top: 20px; bottom: auto; */
    background-color: rgba(218, 170, 50, 0.3);
    width: 150px;
    height: 0;
    border-radius: 0 0 50% 50%;
    box-shadow: rgb(210, 161, 15) 0 0 35px 2px;
  }
  
  .glow {
    top: -100px;
    width: 0px;
    height: 0px;  
    border-radius: 50%;
    color: rgba(255, 221, 68, 0.4);
    box-shadow: 0 0 150px 50px;
    animation: glow 0.5s linear infinite alternate;
  }
  
  .shadow {
    top: 170px;
    width: 220px;
    height: 20px;  
    background-color: #111;
    border-radius: 50%; 
  }
  
  @keyframes glow {
    to{
      box-shadow: 0 0 120px 50px;
    }
  }
  
  