/* Sponsors Section */
.sponsors-section {
  padding: 50px;
  text-align: center;
}

.campa-image-container {
  margin: 20px auto;
  display: flex;
  justify-content: center;
}

.campa-image {
  max-width: 300px; /* Adjust as needed */
  width: 100%;
  height: auto;
  aspect-ratio: 1; /* Ensures a square shape */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 2px solid #ff6600;
  box-shadow: 0 0 8px rgba(255, 102, 0, 0.7);
  transition: transform 0.3s, box-shadow 0.3s;
  background-color: transparent;
  overflow: hidden;
}

.go-back-button {
  position: absolute;
  top: 20px;
  left: 20px;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: bold;
  color: white;
  background-color: #ff7518;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0px 0px 10px rgba(255, 117, 24, 0.6);
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.go-back-button:hover {
  background-color: #e86000;
  box-shadow: 0px 0px 12px rgba(255, 117, 24, 0.8);
  transform: scale(1.05);
}

/* Flickering Heading */
.sponsors-heading {
  font-size: 2em;
  margin-bottom: 30px;
  color: #ff9900;
  text-shadow: 0 0 15px #ff9900, 0 0 25px #ff6600;
  animation: flicker 1.5s infinite alternate;
}

@keyframes flicker {
  0% { opacity: 1; }
  50% { opacity: 0.7; }
  100% { opacity: 1; }
}

/* Sponsors Gallery */
.sponsors-gallery {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 columns for larger cards */
  gap: 20px; /* Increased gap to balance spacing on all sides */
  justify-items: center;
  padding: 0 20px;
}

/* Sponsor Item */
.sponsor-item {
  width: 100%; /* Adjust width to fully use the grid space */
  max-width: 300px; /* Set max width for control */
  aspect-ratio: 1; /* Ensures a square shape */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 2px solid #ff6600;
  box-shadow: 0 0 8px rgba(255, 102, 0, 0.7);
  transition: transform 0.3s, box-shadow 0.3s;
  background-color: transparent;
  overflow: hidden;
}

.sponsor-item:hover {
  transform: scale(1.05);
  box-shadow: 0 0 15px rgba(255, 165, 0, 0.9);
}

.campa-image:hover {
  transform: scale(1.05);
  box-shadow: 0 0 15px rgba(255, 165, 0, 0.9);
}

/* Sponsor Image */
.sponsor-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .sponsors-gallery {
    grid-template-columns: repeat(3, 1fr); /* 3 columns on medium devices */
  }
}

@media (max-width: 768px) {
  .sponsors-gallery {
    grid-template-columns: repeat(2, 1fr); /* 2 columns on smaller screens */
  }
}

@media (max-width: 480px) {
  .sponsors-gallery {
    grid-template-columns: 1fr; /* 1 column on very small screens */
  }
}
