/* From Uiverse.io by vishnu1100 */




.event-img{
  z-index:10;
  position: relative;
  top:20px;
  left :15px;
  height: 200px;
  width:267px;
  justify-content: center;
  background-position: center;
  /* background-image: url("../assets/th.jfif"); */
  opacity: 0.9;
  border-radius: 10% 10% 10% 10%;


}
.event-content{
  position: relative;
  z-index: 20 !important;
  padding: 25px;
}
.frame{
  position: relative;
  margin: 0px;
  height: 370px;
  width: 300px;
  /* border: 3px #ffea00 dashed;
  border-radius: 20px; */
  z-index:30;
  background-image: url("../assets/frame.png");
  background-size: contain;
  background-repeat: no-repeat;
}
.frame-bottom{
  position: relative;
  top:-300px;
  background-image: url("../assets/frame.png");
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 10;
  width: 300px;
  height: 250px;
  transform: rotate(180deg);
}
.spooky-house {
position: relative;
width: 300px; /* Set your desired width */
height: 370px; /* Set your desired height */
perspective: 1000px; /* Enable 3D space */
margin-bottom: 5%;
background-color: rgb(236, 190, 25);
border-radius: 20px;
}

.card-inner {
border: solid white 2px;
border-radius: 20px;
width: 100%;
height: 100%;
transition: transform 0.6s; /* Animation duration */
transform-style: preserve-3d; /* Enable 3D rendering */
}
.card-inner:hover{
  transform: scale(1.05); 
  box-shadow: 0 0 25px rgba(255, 187, 0, 0.9), 0 0 50px rgba(255, 204, 0, 0.7); 
}

.flipped .card-inner {
transform: rotateY(180deg); /* Rotate the card on flip */
}

.card-front, .card-back {
position: absolute;
width: 300px;
height: 370px;
backface-visibility: hidden; /* Hide back side when not flipped */
border-radius: 20px;
}
.card-front{
  /* background-image: url('../assets/event-img/ASCENSION.png'); */
  background-repeat: no-repeat;
  background-size: cover;
  transition: transform 0.3s ease, background-color 0.3s ease;

}

.card-content{
  margin: 3%;
  color: #ffd9b4;
}
.card-content h2{
  color : #ff5500;
}
.card-back {
background: #2b004d; /* Background for the back side */
display: flex;
flex-direction: column;
justify-content: center; /* Center content */
align-items: center; /* Center content */
transform: rotateY(180deg); /* Rotate back side */

border-radius: 20px;
}
.spooky-house{
 
}
.card-back h2 {
margin: 0; /* Remove default margin */
}

.card-back p {
text-align: center; /* Center text */
}




.content-circle:before {
  content: "";
  position: absolute;
  width: 300px;
  height: 150px;
  top: 300px;
  left:0px;
  border-radius: 50% 50% 0 0;
  background-color: #000;
}


.rain {
  position: absolute;
  z-index: 5;
}

.rain:before {
  content: "";
  position: absolute;
  width: 280px;
  height: 350px;
  /* background: #fff; */
  opacity: 0;
  animation: lighting 3s linear infinite;
}

.dropOne,
.dropTwo,
.dropThree,
.dropFour,
.dropFive {
  position: absolute;
  background-color: rgba(211, 211, 211, 0.3);
  height: 10px;
  width: 1px;
  top: 0;
  box-shadow: 0 -270px rgba(211, 211, 211, 0.3),
    -50px -50px rgba(211, 211, 211, 0.3), -50px -150px rgba(211, 211, 211, 0.3),
    50px -395px rgba(211, 211, 211, 0.3), 50px -200px rgba(211, 211, 211, 0.3),
    50px -100px rgba(211, 211, 211, 0.3), 100px -400px rgba(211, 211, 211, 0.3),
    100px -320px rgba(211, 211, 211, 0.3), 100px -150px rgba(211, 211, 211, 0.3),
    150px -200px rgba(211, 211, 211, 0.3), 200px -100px rgba(211, 211, 211, 0.3),
    200px -370px rgba(211, 211, 211, 0.3), 250px -330px rgba(211, 211, 211, 0.3),
    250px -220px rgba(211, 211, 211, 0.3), 300px -70px rgba(211, 211, 211, 0.3),
    300px -140px rgba(211, 211, 211, 0.3), 300px -300px rgba(211, 211, 211, 0.3);
}

.dropOne {
  animation: rain 1.5s linear infinite;
  left: 100px;
}

.dropTwo {
  left: -50px;
  animation: rain 1.2s linear infinite;
}

.dropThree {
  left: 50px;
  animation: rain 1.7s linear infinite;
}

.dropFour {
  left: 150px;
  animation: rain 1.4s linear infinite;
}

.dropFive {
  left: 80px;
  animation: rain 1.3s linear infinite;
}

@keyframes rain {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(1000px);
  }
}

@keyframes lighting {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
  }
  11% {
    opacity: 1;
  }
  14% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  21% {
    opacity: 1;
  }
  24% {
    opacity: 0;
  }
  104% {
    opacity: 0;
  }
}
/* Card transitions */
.spooky-house {
  position: relative;
  width: 300px;
  height: 370px;
  perspective: 1000px;
  margin-bottom: 5%;
  background-color: rgb(236, 190, 25);
  border-radius: 20px;
  transition: all 1s ease-in-out; /* Smooth transition */
  cursor: pointer;
  z-index: 1; /* Default z-index */
}


.flipped .card-inner {
  transform: rotateY(180deg);
}

/* Front and back of the card */
.card-front, .card-back {
  position: absolute;
  width: 300px;
  height: 370px;
  backface-visibility: hidden;
  border-radius: 20px;
}
.card-back{
  height: 100%;
  width: 100%;
}

.card-back {
  background: #2b004d;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: rotateY(180deg);
  border-radius: 20px;
}

/* Expanded state */
.expanded {
  width: 90vw; /* Almost full screen */
  height: 90vh; /* Almost full screen */
  z-index: 999; /* Bring it to front */
  position: fixed; /* Fix it to the screen */
  top: 13%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1); /* Center and scale */
  transition: all 1s ease-in-out; /* Smooth expand transition */
}

.expanded .card-inner {
  width: 100%;
  height: 100%;
}

/* Optional: When card flips, you can increase z-index to make sure it stays on top */
.expanded.flipped {
  z-index: 1000;
}