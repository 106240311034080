/* Artists Section */
.artists-section {
  padding: 50px;
  text-align: center;
}

/* Flickering Heading */
.sponsors-heading {
  font-size: 3em;
  margin-bottom: 20px;
  color: #ff9900;
  text-shadow: 0 0 15px #ff9900, 0 0 25px #ff6600;
  animation: flicker 1.5s infinite alternate;
}

@keyframes flicker {
  0% { opacity: 1; }
  50% { opacity: 0.7; }
  100% { opacity: 1; }
}

/* Cards Container */
.cards-container {
  display: flex;
  justify-content: center;
  gap: 50px;
  flex-wrap: wrap;
}

/* Artist Card */
.artist-card1, .artist-card2 {
  position: relative;
  width: 500px;
  height: 350px;
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 0 0 15px rgba(255, 102, 0, 0.7), 0 0 25px rgba(128, 0, 128, 0.7);
  transition: box-shadow 0.3s ease;
  border: 8px solid #ff6600;
  animation: spookyGlow 1.5s infinite alternate;
}

/* Spooky Glow Animation for Border */
@keyframes spookyGlow {
  0% {
    border-color: #ff3300;
    box-shadow: 0 0 20px #ff3300, 0 0 30px #ff6600, 0 0 40px #ff3300;
  }
  50% {
    border-color: #ff6600;
    box-shadow: 0 0 25px #ff6600, 0 0 35px #ff3300, 0 0 45px #ff6600;
  }
  100% {
    border-color: #ffcc00;
    box-shadow: 0 0 20px #ffcc00, 0 0 30px #ff9900, 0 0 40px #ffcc00;
  }
}

/* Artist Image */
.artist-image, .artist-image2 {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

/* Red Curtain Effect */
.curtain {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: 
    url('../assets/aarohiLogo.png') center center no-repeat,
    repeating-linear-gradient(90deg, #4b0000, #4b0000 10px, #300000 10px, #300000 20px);
  background-color: #300000;
  transition: transform 0.5s ease;
  z-index: 2;
  box-shadow: inset 0 0 15px rgba(0, 0, 0, 0.7);
  background-size: 250px auto, cover;
}

.artist-card1:hover .curtain,
.artist-card2:hover .curtain {
  transform: translateX(-100%);
}

/* Responsive Design */

/* Desktop */
@media (max-width: 1200px) {
  .artist-card1, .artist-card2 {
    width: 450px;
    height: 315px;
  }
}

/* Tablet */
@media (max-width: 1024px) {
  .artist-card1, .artist-card2 {
    width: 400px;
    height: 280px;
  }
}

/* Smaller Tablets and Large Phones */
@media (max-width: 768px) {
  .artist-card1, .artist-card2 {
    width: 350px;
    height: 245px;
  }
}

/* Mobile Phones */
@media (max-width: 480px) {
  .cards-container {
    flex-direction: column;
    align-items: center;
  }
  .artist-card1, .artist-card2 {
    width: 300px;
    height: 210px;
    margin-bottom: 20px;
  }
}
